/* eslint-disable react/no-danger */
import path from 'path';
import fsPromises from 'fs/promises';
import dynamic from 'next/dynamic';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { actions, GET_DETAIL_PROJECT } from '@containers/Home/slice';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectConfigLittlePlanetProject,
  selectConfigFirstSceneProject,
  selectFirstSceneMediaProject,
  selectGATagTrackingProject,
  selectFeatureImageProject,
  selectGATrackingProject,
  selectThumbnailProject,
  selectMetaDataProject,
  selectTitleProject,
  selectListScene,
} from '@containers/Home/selectors';
import { SagaStore, wrapper } from 'store';
import Script from 'next/script';
import {
  LIST_FAVICON_DEFAULT,
  LIST_LINK_STYLE_SHEET,
} from '@containers/Home/constants';
// import { END } from 'redux-saga';
import { useTourEventState } from '@containers/Home/global/TourStats';
import { isServer } from '@utils/helper';
import { END } from 'redux-saga';

const Home = dynamic(() => import('containers/Home'), {
  loading: () => <span>Loading</span>,
});

function ExportContainer() {
  const router = useRouter();

  const [, setTourEventState] = useTourEventState('state');

  const dispatch = useDispatch();
  const title = useSelector(selectTitleProject);
  const listScene = useSelector(selectListScene);
  const metadata = useSelector(selectMetaDataProject);
  const thumbnail = useSelector(selectThumbnailProject);
  const ga_tracking = useSelector(selectGATrackingProject);
  const feature_image = useSelector(selectFeatureImageProject);
  const first_scene = useSelector(selectConfigFirstSceneProject);
  const ggtag_tracking = useSelector(selectGATagTrackingProject);
  const first_scene_media = useSelector(selectFirstSceneMediaProject);
  const config_little_planet = useSelector(selectConfigLittlePlanetProject);

  const [isLoadedClient, setIsLoadedClient] = useState(false);

  const detailFirstScene = useMemo(
    () => listScene.filter(item => item.id === first_scene)[0] || undefined,
    [listScene, first_scene],
  );

  const images = useMemo(() => {
    const imgs: any = [];
    if (thumbnail?.src)
      imgs.push({
        url: thumbnail?.src,
        width: 800,
        height: 600,
        alt: thumbnail?.name,
      });

    if (feature_image)
      imgs.push({
        url: feature_image,
        width: 800,
        height: 600,
        alt: '',
      });

    if (first_scene_media?.src)
      imgs.push({
        url:
          first_scene_media?.src_thumb ||
          first_scene_media?.src_preview ||
          first_scene_media?.src,
        width: 800,
        height: 600,
        alt: first_scene_media?.name,
      });

    return imgs;
  }, [thumbnail, feature_image, first_scene_media]);

  const getData = useCallback(async () => {
    try {
      const dbTourData = await fetch(
        `${process.env.NEXT_PUBLIC_PREFIX_NEXT_STATIC}/db.json`,
      );
      const dbTour = await dbTourData.json();
      dispatch(GET_DETAIL_PROJECT.success(dbTour));
      // const listPostData = await fetch(
      //   `${process.env.NEXT_PUBLIC_PREFIX_NEXT_STATIC}/post.json`,
      // );
      // const listPost: any = await listPostData.json();
      // dispatch(actions.setListPostUsedOnTour(listPost));
      setIsLoadedClient(true);
    } catch (err) {
      console.log(err);
    } finally {
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isServer) getData();
  }, [getData]);

  useEffect(() => {
    if (listScene && listScene.length) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      let scene = detailFirstScene
        ? { ...detailFirstScene }
        : { ...listScene[0] };

      if (router.query.scene)
        scene = {
          ...listScene.filter(item => item.id === router.query.scene)[0],
        } || {
          ...listScene[0],
        };

      if (params.scene) {
        scene = { ...listScene.find(item => item.id === params.scene) } || {
          ...listScene[0],
        };
      }

      const hotspot = scene.hotspots.find(item => item.id === params.star);

      if (hotspot)
        setTourEventState(prev => ({
          ...prev,
          idHotspotStarSelected: hotspot.id,
        }));

      // NOTE: add intro little planet for first scene loaded
      scene.config = { ...scene?.config } || {};
      scene.config = {
        ...scene.config,
        default_view: hotspot
          ? {
              x: hotspot.ath,
              y: hotspot.atv,
              zoom_lv: 60,
            }
          : scene.config?.default_view || {},
        isLittlePlanet: config_little_planet?.enable || false,
        timeTransitionLittlePlanet:
          config_little_planet?.transition_time || 2.0,
      };
      dispatch(actions.setSceneSelected(scene));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listScene.length]);

  return (
    <>
      <NextSeo
        title={metadata?.title || title}
        description={metadata?.description || 'Panoee - Virtual Tour 360'}
        additionalLinkTags={
          metadata?.favicon
            ? [
                ...[
                  {
                    rel: 'shortcut icon',
                    href: metadata?.favicon,
                  },
                ],
                ...LIST_LINK_STYLE_SHEET,
              ]
            : [...LIST_FAVICON_DEFAULT, ...LIST_LINK_STYLE_SHEET]
        }
        openGraph={{
          type: 'website',
          url: 'https://saovietbvnt.vn',
          title: metadata?.title || title,
          description: metadata?.description || 'Panoee - Virtual Tour 360',
          images,
          site_name: metadata?.title || title || 'Panoee Tour',
          profile: {
            username: router.query.username as string,
          },
        }}
        facebook={{
          appId: process.env.NEXT_PUBLIC_FB_APP_ID || '2086263614924092',
        }}
      />
      {ga_tracking && (
        <>
          <Script
            strategy="beforeInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${ga_tracking}`}
          />
          <Script
            id="gg-tag"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${ga_tracking}', {
                  page_path: window.location.pathname,
                });
              `,
            }}
          />
        </>
      )}
      {ggtag_tracking && (
        <>
          <Script
            strategy="beforeInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${ggtag_tracking}`}
          />
          <Script
            id="gg-tag-1"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${ggtag_tracking}');
              `,
            }}
          />
        </>
      )}

      {!!isLoadedClient && <Home isPublic={false} isPreventChangeUrl />}
    </>
  );
}
export const getStaticProps = wrapper.getStaticProps(store => async () => {
  const filePath = path.join(process.cwd(), '/public/project.json');
  const data = await fsPromises.readFile(filePath, 'utf8');
  console.log('🚀 ~ file: index.tsx:249 ~ getStaticProps ~ data', data);
  try {
    await store.dispatch(GET_DETAIL_PROJECT.success(JSON.parse(data)));
    await store.dispatch(END);
    await (store as SagaStore).sagaTask?.toPromise();
  } catch (err) {
    console.log(err);
  }
  return {
    props: {},
  };
});

export default ExportContainer;
